@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-data-grid/lib/styles.css';

body {
  @apply text-base;
  @apply bg-gray-50;
  @apply dark:bg-gray-700;
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
  @apply text-base;
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

.main-viewport {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

nav.app-nav {
  background-color: #091a2f;
}

nav.app-nav .nav-item:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.btn {
  @apply rounded px-3 py-2 text-white bg-blue-600 hover:brightness-75 disabled:bg-gray-300 disabled:text-gray-800 disabled:cursor-default;
}

.btn-secondary {
  @apply rounded px-3 py-2 text-gray-800 border border-gray-300  bg-white hover:bg-app-gray-400 disabled:bg-gray-300 disabled:cursor-default;
}

.btn-link {
  @apply text-blue-600 hover:text-blue-800;
}

.btn-small {
  @apply rounded px-3 py-0.5 text-white text-base bg-blue-600 hover:bg-blue-800 disabled:bg-gray-300 disabled:text-gray-800 disabled:cursor-default;
}

.link {
  @apply underline text-blue-600 hover:text-blue-800;
}

.bg-green-sea {
  background-color: #16a085;
}
.border-green-sea {
  border-color: #16a085;
}

.text-telemetry-timestap {
  font-size: 0.65rem;
}

.check {
  height: calc(1.25rem - 2px);
  width: calc(1.25rem - 2px);
}

.app-text-gray-4 {
  text-color: #bdbdbd;
}

.app-bg-gray-5 {
  background-color: #818182;
}

.app-border-gray-4 {
  border-color: #bdbdbd;
}

.app-bg-blue-note {
  @apply bg-blue-100;
}

.app-bg-yellow-caution {
  @apply bg-amber-100;
}

.app-bg-red-warning {
  @apply bg-red-100;
}

.app-bg-blue-note-dark {
  @apply bg-blue-200;
}

.app-bg-yellow-caution-dark {
  @apply bg-amber-200;
}

.app-bg-red-warning-dark {
  @apply bg-red-200;
}

/**
  * Remove blue shadow/ring/border for react-select input element.
  * TODO: Change tailwindcss-forms to classes and opt-in all form elements.
  * Reference: https://github.com/tailwindlabs/tailwindcss-forms/issues/22
  */
.react-select__input > input,
.react-select__input > input:focus {
  @apply shadow-none ring-0 border-0;
}

/**
  * Targets all the drop down svg elements to style them in blue to match designs.
  * TODO: Either use style properties like react-select supports,
  * or keep this hack to use tailwind.
  */
.settings-select > svg {
  @apply text-blue-600;
}

/**
  * Targets the outer select div to add a matching 2 pixel border
  * TODO: Either use style properties like react-select supports
  * or keep this hack to use tailwind.
  */
.settings-select.react-select__control {
  @apply border-2 border-gray-300;
}

/**
  * Below react-datepicker classes are overridden to add application colors.
  * TODO (DEEP) - Find a more customizable datepicker or move this to its own css file adjascent to where its used.
  */
.react-datepicker__day--selected {
  @apply bg-blue-500 !important;
}

.react-datepicker__day--today {
  @apply bg-blue-500 !important;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-500 !important;
}

.react-datepicker-popper {
  @apply z-10 !important;
}

.callout-triangle-down {
  width: 0;
  height: 0;
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  border-top: 0.75rem solid #cbd5e1; /* #cbd5e1 == blueGray-300 */
}

.absoluteCenter {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.rdg-cell {
  border-style: none;
  border-inline-end: 0px;
  border-block-end: 1px solid var(--rdg-border-color);
  box-sizing: inherit;
  @apply flex;
  @apply w-full;
  @apply h-full;
  @apply items-center;
}
.rdg-header-row {
  font-weight: normal;
}
.rdg {
  border-width: 1px;
}
.rdg-header-sort-name {
  flex: none;
}
.rdg.fill-grid {
  block-size: 100%;
}

.rdg div[aria-selected='true'] {
  outline: none;
}

.rdg-checkbox {
  display: none;
}

.rdg-checkbox-input {
  border-radius: 2px;
  display: inline-block;
  vertical-align: top;
}

/* https://iros.github.io/patternfills/sample_css.html */
.stripe {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc1JyBoZWlnaHQ9JzUnPgogIDxyZWN0IHdpZHRoPSc1JyBoZWlnaHQ9JzUnIGZpbGw9J3doaXRlJy8+CiAgPHBhdGggZD0nTTAgNUw1IDBaTTYgNEw0IDZaTS0xIDFMMSAtMVonIHN0cm9rZT0nIzg4OCcgc3Ryb2tlLXdpZHRoPScxJy8+Cjwvc3ZnPg==');
  background-repeat: repeat;
}

.field-title {
  @apply text-sm text-gray-500 font-medium;
}

/* Custom styling for mention component */

.p-highlight {
  background-color: rgb(219 234 254) !important;
}

.p-mention .p-mention-panel {
  min-width: unset !important;
}

.p-inputtextarea {
  width: 100%;
}

.p-inputtext {
  padding-right: 64px !important;
  padding-left: 12px !important;
  color: rgb(0 0 0) !important;
}

.p-inputwrapper {
  width: 100%;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  @apply border-t-gray-200 !important;
  @apply border-b-0 !important;
  @apply border-l-0 !important;
  @apply border-r-0 !important;
  @apply rounded-none !important;
  @apply bg-white !important;
  @apply px-0.5 !important;
  @apply py-0 !important;
  @apply flex-row-reverse;
  @apply justify-between;
}

.p-accordion .p-accordion-tab {
  @apply my-0 !important;
}

.p-accordion-header-link:focus {
  @apply shadow-none !important;
}

.p-accordion .p-accordion-content {
  @apply border-0 !important;
  @apply bg-white !important;
  @apply px-0.5 !important;
  @apply pb-0 !important;
  @apply pt-0.5 !important;
}

.p-accordion .p-accordion-header {
  @apply border-0 !important;
  @apply bg-white !important;
  @apply border-b-gray-500 !important;
}

.p-virtualscroller-content {
  @apply w-full !important;
}

.p-splitter {
  @apply rounded-none !important;
  @apply print:border-0 !important;
}

.p-splitter-gutter {
  @apply bg-gradient-to-r !important;
  @apply from-gray-200 !important;
  @apply print:hidden !important;
}

.p-component {
  @apply text-base !important;
}

.p-overlaypanel-content {
  @apply p-0 !important;
}

.p-treeselect-label {
  @apply text-base !important;
}

/* Hack to make mentions appear above the input box so comments at the bottom
   of the screen aren't completely invisible.  The component doesn't handle
   collision yet, so this is a 95% solution until they do
   https://github.com/primefaces/primereact/issues/4196 */
.above .p-mention-panel {
  transform: translateY(-100%) !important;
  top: 0 !important;
}

/*
  Hack to get rid of prime react styling on focused input.
*/
#form-comment .p-inputtext {
  box-shadow: none !important;
  border-style: none !important;
  padding: 0 !important;
}

.unobtrusive-scrollbar {
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  scrollbar-color: lightgray transparent;
}
